var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"secondary","height":"10","indeterminate":""}})],1),_vm._v(" "),_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"secondary"}},[_c('ToolBarTitle',{attrs:{"title":_vm.edit ? 'Editar usuario' : 'Agregar usuario'}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c('v-icon',[_vm._v("\n          mdi-close\n        ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"px-5 pb-5"},[_c('validation-observer',{ref:"userObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","data-vv-name":"name","error-messages":errors,"prepend-icon":"mdi-account-circle","autofocus":""},model:{value:(_vm.userForm.name),callback:function ($$v) {_vm.$set(_vm.userForm, "name", $$v)},expression:"userForm.name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellido","data-vv-name":"last_name","error-messages":errors,"prepend-icon":"mdi-account-circle-outline"},model:{value:(_vm.userForm.last_name),callback:function ($$v) {_vm.$set(_vm.userForm, "last_name", $$v)},expression:"userForm.last_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"identity_card","rules":"required|min:3|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Documento de Identidad","data-vv-name":"identity_card","error-messages":errors,"prepend-icon":"mdi-card-account-details"},on:{"input":function (value) { return _vm.userForm.identity_card = value.toUpperCase(); }},model:{value:(_vm.userForm.identity_card),callback:function ($$v) {_vm.$set(_vm.userForm, "identity_card", $$v)},expression:"userForm.identity_card"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"area_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"name","item-value":"id","label":"Sección","data-vv-name":"area_id","error-messages":errors,"prepend-icon":"mdi-briefcase"},model:{value:(_vm.userForm.area_id),callback:function ($$v) {_vm.$set(_vm.userForm, "area_id", $$v)},expression:"userForm.area_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required|min:3|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de usuario","data-vv-name":"username","error-messages":errors,"prepend-icon":"mdi-account"},model:{value:(_vm.userForm.username),callback:function ($$v) {_vm.$set(_vm.userForm, "username", $$v)},expression:"userForm.username"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","data-vv-name":"email","error-messages":errors,"prepend-icon":"mdi-at"},model:{value:(_vm.userForm.email),callback:function ($$v) {_vm.$set(_vm.userForm, "email", $$v)},expression:"userForm.email"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|min:7|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","data-vv-name":"phone","error-messages":errors,"prepend-icon":"mdi-phone"},model:{value:(_vm.userForm.phone),callback:function ($$v) {_vm.$set(_vm.userForm, "phone", $$v)},expression:"userForm.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Dirección","data-vv-name":"address","error-messages":errors,"prepend-icon":"mdi-map-marker"},model:{value:(_vm.userForm.address),callback:function ($$v) {_vm.$set(_vm.userForm, "address", $$v)},expression:"userForm.address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}},[(_vm.edit)?_c('validation-provider',{attrs:{"name":"password","rules":_vm.edit ? '' : 'required|min:4'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contraseña","data-vv-name":"password","error-messages":errors,"prepend-icon":"mdi-lock","append-icon":_vm.shadowPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.shadowPassword ? 'password' : 'text'},on:{"click:append":function () { return (_vm.shadowPassword = !_vm.shadowPassword); }},model:{value:(_vm.userForm.password),callback:function ($$v) {_vm.$set(_vm.userForm, "password", $$v)},expression:"userForm.password"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"6"}}),_vm._v(" "),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","type":"submit","color":"info","disabled":invalid || _vm.loading}},[_vm._v("\n                  Guardar\n                ")])],1)],1)],1)],1)]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('ToolBarTitle',{attrs:{"title":"Regularización de Documentos"}}),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-row',{staticClass:"mt-1 px-4 pb-0 mb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"9","lg":"9","md":"9","sm":"8","xs":"12"}},[_c('div',{staticClass:"text-xl-h5 text-lg-h5 text-md-h6 text-sm-subtitle-1 text-xs-body-1"},[_vm._v(_vm._s(_vm.area(_vm.$store.getters.user.area_id)))])]),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"3","lg":"3","md":"3","sm":"4","xs":"12"}},[_c('SearchInput',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procedures,"options":_vm.options,"server-items-length":_vm.totalProcedures,"loading":_vm.loading,"footer-props":{
              itemsPerPageOptions: [8, 15, 30]
            },"mobile-breakpoint":"0","dense":"","id":"datatable"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.incoming_at",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'L'))+"\n              ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'LT'))+"\n              ")])]}},{key:"item.from_area",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.area(item.from_area ? item.from_area : 0))+"\n            ")]}},{key:"item.procedure_type_id",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.procedureType(item.procedure_type_id))+"\n            ")]}},{key:"item.to_area",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"dark":_vm.$store.getters.user.id == item.user_id,"rounded":"","color":"orange darken-1","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":_vm.$store.getters.user.id != item.user_id},on:{"click":function($event){return _vm.$refs.dialogProcedureRequirements.showDialog(item)}}},[_c('div',{staticClass:"text-center text-responsive"},[_vm._v("\n                  Requisitos\n                ")])])]}}],null,true)})],1)],1)],1)],1),_vm._v(" "),_c('ProcedureRequirements',{ref:"dialogProcedureRequirements",attrs:{"procedure-types":_vm.procedureTypes},on:{"updateList":_vm.fetchProcedures}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('ToolBarTitle',{attrs:{"title":"Reporte dinámico de correspondencia"}}),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('validation-observer',{ref:"searchObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.getReport.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-2 pb-0 mb-0 px-xl-16 px-lg-16 mx-xl-16 mx-lg-16"},[_c('v-col',{staticClass:"pb-0 mb-0 pr-0",attrs:{"xl":"4","lg":"4","md":"4","sm":"4","xs":"12"}},[_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fecha inicial","data-vv-name":"start_date","error-messages":errors,"prepend-icon":"mdi-calendar","persistent-hint":"","hint":"DD/MM/AAAA"},model:{value:(_vm.reportForm.start_date),callback:function ($$v) {_vm.$set(_vm.reportForm, "start_date", $$v)},expression:"reportForm.start_date"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0 pl-0",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","xs":"12"}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-sm-5 mt-md-3",attrs:{"color":"success","elevation":0,"small":_vm.$vuetify.breakpoint.sm,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.menuDate.start = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"25px !important"}},[_vm._v("\n                        mdi-calendar\n                      ")])],1)]}}],null,true),model:{value:(_vm.menuDate.start),callback:function ($$v) {_vm.$set(_vm.menuDate, "start", $$v)},expression:"menuDate.start"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menuDate.start = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0 pr-0",attrs:{"xl":"4","lg":"4","md":"4","sm":"4","xs":"12"}},[_c('validation-provider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fecha final","data-vv-name":"end_date","error-messages":errors,"prepend-icon":"mdi-calendar","persistent-hint":"","hint":"DD/MM/AAAA"},model:{value:(_vm.reportForm.end_date),callback:function ($$v) {_vm.$set(_vm.reportForm, "end_date", $$v)},expression:"reportForm.end_date"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0 pl-0",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","xs":"12"}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-sm-5 mt-md-3",attrs:{"color":"success","elevation":0,"small":_vm.$vuetify.breakpoint.sm,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.menuDate.end = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"25px !important"}},[_vm._v("\n                        mdi-calendar\n                      ")])],1)]}}],null,true),model:{value:(_vm.menuDate.end),callback:function ($$v) {_vm.$set(_vm.menuDate, "end", $$v)},expression:"menuDate.end"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menuDate.end = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1):_vm._e()],1)],1),_vm._v(" "),_c('v-row',{staticClass:"mt-2 pb-0 mb-0 px-xl-16 px-lg-16 mx-xl-16 mx-lg-16"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","xs":"12"}},[_c('validation-provider',{attrs:{"name":"area_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"name","item-value":"id","label":"Área","data-vv-name":"area_id","error-messages":errors,"prepend-icon":"mdi-briefcase","disabled":!_vm.$store.getters.user.isAdmin},model:{value:(_vm.reportForm.area_id),callback:function ($$v) {_vm.$set(_vm.reportForm, "area_id", $$v)},expression:"reportForm.area_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"mt-2 pb-0 mb-0 px-xl-16 px-lg-16 mx-xl-16 mx-lg-16"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","xs":"12"}},[_c('validation-provider',{attrs:{"name":"procedure_type_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.procedureTypes,"item-text":"name","item-value":"id","label":"Tipo de trámite","data-vv-name":"procedure_type_id","error-messages":errors,"prepend-icon":"mdi-paperclip"},model:{value:(_vm.reportForm.procedure_type_id),callback:function ($$v) {_vm.$set(_vm.reportForm, "procedure_type_id", $$v)},expression:"reportForm.procedure_type_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"mt-2 pb-0 mb-0 px-xl-16 px-lg-16 mx-xl-16 mx-lg-16"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","xs":"12"}},[_c('validation-provider',{attrs:{"name":"search_by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.searchParameters,"item-text":"text","item-value":"value","label":"Criterios de Selección","data-vv-name":"search_by","error-messages":errors,"prepend-icon":"mdi-tray"},model:{value:(_vm.reportForm.search_by),callback:function ($$v) {_vm.$set(_vm.reportForm, "search_by", $$v)},expression:"reportForm.search_by"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"my-0 px-4 pt-2"},[_c('v-col',{staticClass:"text-end pr-6",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","type":"submit","disabled":invalid || _vm.loading}},[_vm._v("Generar en PDF")])],1),_vm._v(" "),_c('v-col',{staticClass:"text-start pl-6",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"yellow","disabled":Object.values(_vm.reportForm).every(function (i) { return i === null; }) || _vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.clearForm.apply(null, arguments)}}},[_vm._v("Limpiar")])],1)],1),_vm._v(" "),_c('v-row',{staticClass:"my-0 px-4 pt-2"},[_c('v-col',{staticClass:"text-center red--text",attrs:{"cols":"12"}},[_vm._v("\n                "+_vm._s(_vm.errorMessage)+"\n              ")])],1)],1)]}}])}),_vm._v(" "),_c('v-card-footer',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","height":"4","indeterminate":""}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
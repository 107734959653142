var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#235063","dark":""}},[_c('ToolBarTitle',{attrs:{"title":"Libro de registro de correspondencia"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.$store.getters.user.permissions.includes('CREAR TRÁMITE'))?_c('v-divider',{staticClass:"mx-5",attrs:{"vertical":""}}):_vm._e(),_vm._v(" "),(_vm.$store.getters.user.permissions.includes('CREAR TRÁMITE'))?_c('AddButton',{attrs:{"text":"Agregar hoja de ruta"},on:{"click":function($event){return _vm.$refs.dialogProcedureForm.showDialog()}}}):_vm._e()],1),_vm._v(" "),_c('v-row',{staticClass:"mt-1 px-4 pb-0 mb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-xl-h5 text-lg-h5 text-md-h6 text-sm-subtitle-1 text-xs-body-1"},[_vm._v(_vm._s(_vm.area(_vm.$store.getters.user.area_id)))])])],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procedures,"options":_vm.options,"server-items-length":_vm.totalProcedures,"loading":_vm.loading,"footer-props":{
              itemsPerPageOptions: [8, 15, 30]
            },"mobile-breakpoint":"0","dense":"","id":"datatable"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.incoming_at",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'L'))+"\n              ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'LT'))+"\n              ")])]}},{key:"item.from_area",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.area(item.from_area ? item.from_area : 0))+"\n            ")]}},{key:"item.procedure_type_id",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.procedureType(item.procedure_type_id))+"\n            ")]}},{key:"item.to_area",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[(item.archived && item.owner)?_c('div',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-center"},[_vm._v("\n                      Archivado\n                    ")])])],1):_c('div',[(_vm.$store.getters.user.permissions.includes('DERIVAR TRÁMITE') && item.owner && _vm.procedureTypes.length > 0)?_c('v-col',{attrs:{"cols":"6"}},[(item.verified || _vm.$store.getters.user.role != 'VERIFICADOR')?_c('div',[_c('v-btn',{staticClass:"mx-0",attrs:{"rounded":"","color":"green darken-1","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":!_vm.$helpers.userOwnsProcedure(item.user_id)},on:{"click":function($event){_vm.$refs.dialogProcedureFlow.showDialog(item, _vm.procedureType(item.procedure_type_id))}}},[_c('div',{staticClass:"text-center white--text text-responsive"},[_vm._v("\n                          Derivar\n                        ")])])],1):_c('div',[_c('v-btn',{staticClass:"mx-0",attrs:{"rounded":"","color":"orange darken-1","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":!_vm.$helpers.userOwnsProcedure(item.user_id)},on:{"click":function($event){return _vm.$refs.dialogProcedureRequirements.showDialog(item)}}},[_c('div',{staticClass:"text-center white--text text-responsive"},[_vm._v("\n                        Requisitos\n                      ")])])],1)]):_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-center"},[_vm._v("\n                      "+_vm._s(_vm.area(item.to_area))+"\n                    ")])])],1)])]}},{key:"item.outgoing_at",fn:function(ref){
            var item = ref.item;
return [(item.archived)?_c('div',[_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.updated_at,'L'))+"\n                ")]),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.updated_at,'LT'))+"\n                ")])]):(!item.outgoing_at)?_c('div',[_vm._v("\n                -\n              ")]):_c('div',[_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.outgoing_at,'L'))+"\n                ")]),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.outgoing_at,'LT'))+"\n                ")])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"dense":"","no-gutters":"","justify":"space-around","align":"center"}},[(!item.has_flowed && item.owner && !item.archived && _vm.$store.getters.user.permissions.includes('EDITAR TRÁMITE'))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"color":"info","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":!_vm.$helpers.userOwnsProcedure(item.user_id)},on:{"click":function($event){return _vm.$refs.dialogProcedureForm.showDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-pencil\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar Hoja de Ruta")])])],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"color":"error","dark":"","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs},on:{"click":function($event){_vm.$refs.dialogProcedureAttachments.showDialog(item, _vm.procedureType(item.procedure_type_id))}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-file-document-multiple\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Archivos adjuntos")])])],1),_vm._v(" "),(_vm.$store.getters.user.permissions.includes('ARCHIVAR TRÁMITE') && item.has_flowed && item.owner && !item.archived)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"color":"warning lighten-1","dark":_vm.$store.getters.user.id == item.user_id,"small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":!_vm.$helpers.userOwnsProcedure(item.user_id)},on:{"click":function($event){return _vm.$refs.dialogProcedureArchive.showDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-folder\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Archivar")])])],1):_vm._e(),_vm._v(" "),(!item.has_flowed && item.owner && !item.archived && _vm.$store.getters.user.permissions.includes('ELIMINAR TRÁMITE'))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"color":"warning darken-1","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs,"disabled":!_vm.$helpers.userOwnsProcedure(item.user_id)},on:{"click":function($event){return _vm.$refs.dialogProcedureDelete.showDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-delete\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])])],1):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1),_vm._v(" "),_c('ProcedureForm',{ref:"dialogProcedureForm",attrs:{"procedure-types":_vm.procedureTypes},on:{"updateList":_vm.fetchProcedures}}),_vm._v(" "),_c('ProcedureDelete',{ref:"dialogProcedureDelete",on:{"updateList":_vm.fetchProcedures}}),_vm._v(" "),_c('ProcedureFlow',{ref:"dialogProcedureFlow",attrs:{"areas":_vm.areas},on:{"updateList":_vm.fetchProcedures}}),_vm._v(" "),_c('ProcedureRequirements',{ref:"dialogProcedureRequirements",attrs:{"procedure-types":_vm.procedureTypes},on:{"updateList":_vm.fetchProcedures}}),_vm._v(" "),_c('ProcedureArchive',{ref:"dialogProcedureArchive",on:{"updateList":_vm.fetchProcedures}}),_vm._v(" "),_c('ProcedureAttachments',{ref:"dialogProcedureAttachments"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('ToolBarTitle',{attrs:{"title":"Seguimiento de correspondencia"}}),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('validation-observer',{ref:"searchObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.fetchProcedures.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-2 pb-0 mb-0 px-xl-16 px-lg-16 mx-xl-16 mx-lg-16"},[_c('v-col',{staticClass:"pb-0 mb-0 pr-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","xs":"12"}},[_c('validation-provider',{attrs:{"name":"search","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Buscar","data-vv-name":"search","error-messages":errors,"prepend-icon":"mdi-magnify","persistent-hint":_vm.search_by == 'created_at',"hint":_vm.search_by == 'created_at' ? 'DD/MM/AAAA' : ''},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0 pl-0",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","xs":"12"}},[(_vm.search_by == 'created_at' && !_vm.$vuetify.breakpoint.xs)?_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-sm-5 mt-md-3",attrs:{"color":"success","elevation":0,"small":_vm.$vuetify.breakpoint.sm},on:{"click":function($event){$event.stopPropagation();_vm.menuDate = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"25px !important"}},[_vm._v("\n                        mdi-calendar\n                      ")])],1)]}}],null,true),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","xs":"12"}},[_c('validation-provider',{attrs:{"name":"search_by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.searchParameters,"item-text":"text","item-value":"value","label":"Por","data-vv-name":"search_by","error-messages":errors},model:{value:(_vm.search_by),callback:function ($$v) {_vm.search_by=$$v},expression:"search_by"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"my-0 px-4 pb-4",attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":invalid || _vm.loading}},[_vm._v("Buscar")])],1)],1)],1)]}}])}),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procedures,"options":_vm.options,"server-items-length":_vm.totalProcedures,"loading":_vm.loading,"footer-props":{
              itemsPerPageOptions: [8, 15, 30]
            },"single-select":true,"show-select":"","mobile-breakpoint":"0","dense":"","id":"datatable"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-radio-group',{model:{value:(_vm.selectedProcedure),callback:function ($$v) {_vm.selectedProcedure=$$v},expression:"selectedProcedure"}},[_c('v-radio',{attrs:{"value":item.id,"color":"blue lighten-1"}})],1)],1)],1)]}},{key:"item.incoming_at",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'L'))+"\n              ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm._f("moment")(item.incoming_at ? item.incoming_at : item.created_at,'LT'))+"\n              ")])]}},{key:"item.from_area",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.area(item.from_area ? item.from_area : 0))+"\n            ")]}},{key:"item.procedure_type_id",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.procedureType(item.procedure_type_id))+"\n            ")]}},{key:"item.to_area",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[(item.archived && item.owner)?_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-center"},[_vm._v("\n                    Archivado\n                  ")])]):_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-center"},[_vm._v("\n                    "+_vm._s(_vm.area(item.to_area))+"\n                  ")])])],1)]}},{key:"item.outgoing_at",fn:function(ref){
            var item = ref.item;
return [(item.archived)?_c('div',[_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.updated_at,'L'))+"\n                ")]),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.updated_at,'LT'))+"\n                ")])]):(!item.outgoing_at)?_c('div',[_vm._v("\n                -\n              ")]):_c('div',[_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.outgoing_at,'L'))+"\n                ")]),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm._f("moment")(item.outgoing_at,'LT'))+"\n                ")])])]}}],null,true)}),_vm._v(" "),_c('v-row',{staticClass:"my-0 px-4 pt-2"},[_c('v-col',{staticClass:"text-end pr-6",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"info","disabled":_vm.selectedProcedure == null},on:{"click":function($event){$event.stopPropagation();_vm.$refs.dialogProcedureTimeline.showDialog(_vm.procedures.find(function (o) { return o.id == _vm.selectedProcedure; }))}}},[_vm._v("Registro de recepciones")])],1),_vm._v(" "),_c('v-col',{staticClass:"text-start pl-6",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.search == null || _vm.search_by == null},on:{"click":function($event){$event.stopPropagation();return _vm.clearForm.apply(null, arguments)}}},[_vm._v("Limpiar")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('ProcedureTimeline',{ref:"dialogProcedureTimeline"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"secondary","height":"10","indeterminate":""}})],1),_vm._v(" "),_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"secondary"}},[_c('ToolBarTitle',{attrs:{"title":_vm.edit ? 'Editar hoja de ruta' : 'Agregar hoja de ruta'}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c('v-icon',[_vm._v("\n          mdi-close\n        ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"px-5 pb-5"},[_c('validation-observer',{ref:"procedureObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Fecha de recepción","value":_vm.$moment(_vm.procedureForm.created_at || new Date()).format('L'),"prepend-icon":"mdi-calendar","outlined":"","readonly":""}}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"procedure_type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.procedureTypes,"item-text":"name","item-value":"id","label":"Tipo de trámite","data-vv-name":"procedure_type_id","error-messages":errors,"prepend-icon":"mdi-paperclip"},model:{value:(_vm.procedureForm.procedure_type_id),callback:function ($$v) {_vm.$set(_vm.procedureForm, "procedure_type_id", $$v)},expression:"procedureForm.procedure_type_id"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"code","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"codeField",attrs:{"label":"Código de hoja de ruta","data-vv-name":"code","error-messages":errors,"prepend-icon":"mdi-barcode-scan","clearable":""},model:{value:(_vm.procedureForm.code),callback:function ($$v) {_vm.$set(_vm.procedureForm, "code", $$v)},expression:"procedureForm.code"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"origin","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"originField",attrs:{"label":"Procedencia","data-vv-name":"origin","error-messages":errors,"prepend-icon":"mdi-email-open"},model:{value:(_vm.procedureForm.origin),callback:function ($$v) {_vm.$set(_vm.procedureForm, "origin", $$v)},expression:"procedureForm.origin"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"detail","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Detalle/Asunto","data-vv-name":"detail","error-messages":errors,"prepend-icon":"mdi-message-processing"},model:{value:(_vm.procedureForm.detail),callback:function ($$v) {_vm.$set(_vm.procedureForm, "detail", $$v)},expression:"procedureForm.detail"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","type":"submit","color":"info","disabled":invalid || _vm.loading}},[_vm._v("\n              Guardar\n            ")])],1)],1)]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
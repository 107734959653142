var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('ToolBarTitle',{attrs:{"title":"Trámites"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-divider',{staticClass:"mx-5",attrs:{"vertical":""}}),_vm._v(" "),_c('AddButton',{attrs:{"text":"Agregar trámite"},on:{"click":function($event){return _vm.$refs.dialogProcedureTypeForm.showDialog()}}})],1),_vm._v(" "),_c('v-row',{staticClass:"mt-1",attrs:{"align":"end","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto","md":"3","sm":"4","xs":"12"}},[_c('SearchInput',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procedureTypes,"options":_vm.options,"server-items-length":_vm.totalProcedureTypes,"loading":_vm.loading,"footer-props":{
              itemsPerPageOptions: [8, 15, 30]
            },"mobile-breakpoint":"0","dense":"","id":"datatable"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.requirements.length)?{key:"item.requirements",fn:function(ref){
            var item = ref.item;
return [(item.requirements.length > 0)?_c('ol',{staticClass:"my-2"},_vm._l((item.requirements),function(requirementId){return _c('li',{key:requirementId},[_vm._v("\n                  "+_vm._s(_vm.requirements.find(function (o) { return o.id === requirementId; }).name)+"\n                ")])}),0):_c('div',[_vm._v("\n                NINGUNO\n              ")])]}}:null,{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"dense":"","no-gutters":"","justify":"space-around","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"color":"yellow","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs},on:{"click":function($event){return _vm.$refs.dialogProcedureTypeForm.showDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-pencil\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar trámite")])])],1),_vm._v(" "),(item.total_procedures == 0)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 py-4 mx-0 my-1",attrs:{"dark":"","color":"red","small":_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs},on:{"click":function($event){return _vm.$refs.dialogProcedureTypeDelete.showDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","small":_vm.$vuetify.breakpoint.md,"x-small":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs}},[_vm._v("\n                          mdi-delete\n                        ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar trámite")])])],1):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1),_vm._v(" "),_c('ProcedureTypeForm',{ref:"dialogProcedureTypeForm",attrs:{"requirements":_vm.requirements},on:{"updateList":_vm.fetchProcedureTypes}}),_vm._v(" "),_c('ProcedureTypeDelete',{ref:"dialogProcedureTypeDelete",on:{"updateList":_vm.fetchProcedureTypes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }